import React from "react";
import WagmiProvider from "../wagmi/provider";
// import WalletButton from "../Wallet/Wallet";
const Header = () => {
  return (
    <WagmiProvider>
      <header id="header">
        {/* Navbar */}
        <nav
          data-aos="zoom-out"
          data-aos-delay={800}
          className="navbar gameon-navbar navbar-expand"
        >
          <div className="container header">
            {/* Logo */}
            <a className="navbar-brand" href="/">
              <img src="/img/logo.png" alt="Brand Logo" />
            </a>
            <div className="ml-auto" />
            {/* Navbar Nav */}
            {/* <ul className="navbar-nav items mx-auto">
              <li className="nav-item">
                <a href="#home" className="nav-link">
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a href="#about" className="nav-link">
                  About Us
                </a>
              </li>
              <li className="nav-item">
                <a href="#whyUs" className="nav-link">
                  Why Choose Us
                </a>
              </li>
              <li className="nav-item">
                <a href="#program" className="nav-link">
                  About Program
                </a>
              </li>
            </ul> */}
            {/* <WalletButton /> */}
            {/* Navbar Toggler */}
            {/* <ul className="navbar-nav toggle">
              <li className="nav-item">
                <a
                  href="#"
                  className="nav-link"
                  data-toggle="modal"
                  data-target="#menu"
                >
                  <i className="icon-menu m-0" />
                </a>
              </li>
            </ul>             */}
          </div>
        </nav>
      </header>
    </WagmiProvider>
  );
};
export default Header;
