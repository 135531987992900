import React, { Component } from 'react';
import Header from '../components/Header/Header';
import Project from '../components/Project/ProjectSingle';
import Footer from '../components/Footer/Footer';
import ModalMenu from '../components/Modal/ModalMenu';
import Cta from '../components/Cta/Cta';
import Hero from '../components/Hero/Hero';
class ProjectSingle extends Component {
    render() {
        return (
            <div className="main">
                <Header />
                {/* <Breadcrumb title="Project Single" subpage="Project" page="Project Single" /> */}
                <Hero />
                <Project />
                <Cta />
                <Footer />
                {/* <ModalSearch /> */}
                <ModalMenu />
            </div>
        );
    }
}

export default ProjectSingle;