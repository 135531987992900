"use client"
import React from 'react';
import { WagmiConfig, configureChains, createConfig } from 'wagmi';
import { publicProvider } from 'wagmi/providers/public';
import { InjectedConnector } from 'wagmi/connectors/injected';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { bscTestnet, bsc } from 'viem/chains';

const WagmiProvider = ({ children }) => {
    const { chains, publicClient, webSocketPublicClient } = configureChains(
        [bscTestnet, bsc],
        [publicProvider()]
        );

const config = createConfig({
    connectors: [
        new InjectedConnector({
            chains,
            options: {
                name: 'Injected',
                shimDisconnect: true
            },

        }),
        new WalletConnectConnector({
            chains,
            options: {
                projectId: "3c82b21f45766729ae214a9fc8271917",
            },
        }),
    ],
    autoConnect: true,
    publicClient,
    webSocketPublicClient,
});
return <WagmiConfig config={config}>{children}</WagmiConfig>
};
export default WagmiProvider;