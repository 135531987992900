import React, { Component } from "react";

const mainData = {
  img: "/img/vr.gif",
  title: "Explore a New Dimension of Possibilities",
  content: "Welcome to METAVE, where innovation meets imagination. Our metaverse is not just a virtual space; it's a gateway to endless possibilities, a convergence of technology and creativity that opens the doors to a new era of experiences.",
};

const campusData = {
  img: "/img/virtual-Spaces.gif",
  title: "Learning Redefined",
  content:
    "Immerse yourself in the future of education with our Virtual Reality Campus. Attend virtual classes, collaborate with peers in real-time, and engage in hands-on learning experiences. Your journey to knowledge begins here.",
};

const artData = {
  img: "/img/magicWorld.gif",
  title: "Where Art Comes to Life",
  content:
    "Step into our Digital Art Gallery, a vibrant space where artists from around the world showcase their digital masterpieces. Explore galleries, attend art events, and even own a piece of the digital art world using our native METVE tokens.",
};

const entertainData = {
  img: "/img/voting.gif",
  title: "Unleash Your Entertainment Experience",
  content:"Get ready for a new era of entertainment! Our metaverse is your ticket to virtual concerts, movie premieres, and interactive gaming experiences. Use METVE tokens to access exclusive content and be at the forefront of the entertainment revolution.",
  };
const greenData = {
  img: "/img/eco-unscreen.gif",
  title: "Nurturing a Greener Tomorrow",
  content:
    "Join us in building a sustainable future. Our Eco-Friendly Metaverse promotes environmental awareness and allows users to participate in virtual eco-friendly activities. A portion of METVE token sales contributes to real-world environmental causes.",
};
const decentData = {
  img: "/img/city.gif",
  title: "Your Gateway to the Virtual Economy",
  content:
    "Discover a decentralized marketplace for virtual goods and services. Buy, sell, and trade virtual assets using METVE tokens, and be part of a revolutionary virtual economy shaping the metaverse landscape.",
};
const healthData = {
  img: "/img/fit.gif",
  title: "Nourishing the Mind and Body",
  content:
    "Prioritize your well-being in our Health and Wellness Oasis. Attend virtual fitness classes, practice mindfulness, and connect with a community that values holistic living. METVE tokens unlock premium wellness content and experiences.",
};
const real_estateData = {
  img: "/img/realestate.gif",
  title: "Own a Piece of the Virtual World",
  content:
    "Become a virtual property owner in our metaverse. Buy, sell, and trade virtual real estate using METVE tokens. Shape the landscape of our virtual world and embark on a journey of virtual property development.",
};
const timeTravelData = {
  img: "/img/timeTravel.gif",
  title: "Journey through the Ages",
  content:
    "Join us in the exploration of virtual planets and space missions. Contribute to scientific endeavors and be part of a community that reaches for the stars. METVE tokens fund virtual space exploration initiatives.",
};
const spaceData = {
  img: "/img/spaceTravel.gif",
  title: "Reach for the Stars",
  content:
    "Embark on a time-traveling adventure that spans historical eras. Use METVE tokens to unlock new time periods and experience history like never before. The past comes to life in our immersive metaverse.",
};
const communityData = {
  img: "/img/community.gif",
  title: "Your Voice Matters",
  content:
    "Our metaverse is shaped by the community. Propose and vote on new features, events, and initiatives using METVE tokens. Be an integral part of the decision-making process and help us create a metaverse that truly belongs to its users.",
};

const socialData = [
  {
    id: "1",
    link: "twitter",
    icon: "fab fa-twitter",
  },
  {
    id: "2",
    link: "telegram",
    icon: "fab fa-telegram",
  },
  {
    id: "3",
    link: "globe",
    icon: "fas fa-globe",
  },
  {
    id: "4",
    link: "discord",
    icon: "fab fa-discord",
  },
];

class ProjectSingle extends Component {
  state = {
    mainData: {},
    campusData: {},
    artData: {},
    entertainData: {},
    greenData: {},
    decentData: {},
    healthData: {},
    real_estateData: {},
    timeTravelData: {},
    spaceData: {},
    communityData: {},
    socialData: [],
  };
  componentDidMount() {
    this.setState({
      mainData: mainData,
      campusData: campusData,
      artData: artData,
      entertainData: entertainData,
      greenData: greenData,
      decentData: decentData,
      healthData: healthData,
      real_estateData: real_estateData,
      timeTravelData: timeTravelData,
      spaceData: spaceData,
      communityData: communityData,
      socialData: socialData,
    });
  }
  render() {
    return (
      <section className="item-details-area">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-12 col-lg-12 items mt-lg-0">
              <div
                className="card project-card single-item-content no-hover item ml-lg-4"
                id="home"
              >
                <div className="col-12 col-md-5 text-center items-center mb-4">
                  <img src={this.state.mainData.img} alt="project" />
                </div>
                <h5 className="m-0">{this.state.mainData.title}</h5>
                <p>{this.state.mainData.content}</p>
                {/* <p>{this.state.mainData.content_2}</p> */}
              </div>
              <div
                className="card project-card single-item-content no-hover item ml-lg-4"
                id="about"
              >
                <div className="col-12 col-md-5 text-center mb-4">
                  <img src={this.state.campusData.img} alt="" />
                </div>
                <h5 className="m-0">{this.state.campusData.title}</h5>
                <p className="mb-0">{this.state.campusData.content}</p>
              </div>
              <div
                className="card project-card single-item-content no-hover item ml-lg-4"
                id="whyUs"
              >
                <div className="col-12 col-md-5 text-center items-center mb-4">
                  <img src={this.state.artData.img} alt="" />
                </div>
                <h5 className="m-0">{this.state.artData.title}</h5>
                <p>{this.state.artData.content}</p>
              </div>
              <div
                className="card project-card single-item-content no-hover item ml-lg-4"
                id="program"
              >
                <div className="col-12 col-md-5 text-center items-center mb-4">
                  <img src={this.state.entertainData.img} alt="" />
                </div>
                <h5 className="m-0">{this.state.entertainData.title}</h5>
                <p>{this.state.entertainData.content}</p>
              </div>
              <div
                className="card project-card single-item-content no-hover item ml-lg-4"
                id="program"
              >
                <div className="col-12 col-md-5 text-center items-center mb-4">
                  <img src={this.state.greenData.img} alt="" />
                </div>
                <h5 className="m-0">{this.state.greenData.title}</h5>
                <p>{this.state.greenData.content}</p>
              </div>
              <div
                className="card project-card single-item-content no-hover item ml-lg-4"
                id="program"
              >
                <div className="col-12 col-md-5 text-center items-center mb-4">
                  <img src={this.state.decentData.img} alt="" />
                </div>
                <h5 className="m-0">{this.state.greenData.title}</h5>
                <p>{this.state.decentData.content}</p>
              </div>
              <div
                    className="card project-card single-item-content no-hover item ml-lg-4"
                    id="program"
                  >
                    <div className="col-12 col-md-5 text-center items-center mb-4">
                      <img src={this.state.healthData.img} alt="" />
                    </div>
                    <h5 className="m-0">{this.state.healthData.title}</h5>
                    <p>{this.state.healthData.content}</p>
              </div>
              <div
                    className="card project-card single-item-content no-hover item ml-lg-4"
                    id="program"
                  >
                    <div className="col-12 col-md-5 text-center items-center mb-4">
                      <img src={this.state.real_estateData.img} alt="" />
                    </div>
                    <h5 className="m-0">{this.state.real_estateData.title}</h5>
                    <p>{this.state.real_estateData.content}</p>
              </div>
              <div
                    className="card project-card single-item-content no-hover item ml-lg-4"
                    id="program"
                  >
                    <div className="col-12 col-md-5 text-center items-center mb-4">
                      <img src={this.state.timeTravelData.img} alt="" />
                    </div>
                    <h5 className="m-0">{this.state.timeTravelData.title}</h5>
                    <p>{this.state.timeTravelData.content}</p>
              </div>
              <div
                    className="card project-card single-item-content no-hover item ml-lg-4"
                    id="program"
                  >
                    <div className="col-12 col-md-5 text-center items-center mb-4">
                      <img src={this.state.spaceData.img} alt="" />
                    </div>
                    <h5 className="m-0">{this.state.spaceData.title}</h5>
                    <p>{this.state.spaceData.content}</p>
              </div>
              <div
                    className="card project-card single-item-content no-hover item ml-lg-4"
                    id="program"
                  >
                    <div className="col-12 col-md-5 text-center items-center mb-4">
                      <img src={this.state.communityData.img} alt="" />
                    </div>
                    <h5 className="m-0">{this.state.communityData.title}</h5>
                    <p>{this.state.communityData.content}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ProjectSingle;
