import React, { Component } from 'react';
// import WagmiProvider from '../wagmi/provider';
// import WalletButton from '../Wallet/Wallet';

const ctaData = {
  "sub_heading": "How It Works",
  "heading": "How To Participate",
  "img": "/img/rocket-joypixels.gif",
  "title": "Apply for IGO",
  "content": "Get access to a huge set of tools to seamlessly handle your game's integration with blockchain.",
  "btn": "Apply Now",
  "btnIcon": "icon-rocket mr-2"
};

class Cta extends Component {
  state = {
    ctaData: {}
  };

  componentDidMount() {
    this.setState({
      ctaData: ctaData
    });
  }

  render() {
    const { img, btnIcon } = this.state.ctaData;

    return (
      <section className="cta-area p-1">
        <div className="container">
          <div className="row">
            <div className="col-12 card">
              <div className="row align-items-center justify-content-center">
                <div className="col-12 col-md-5 text-center">
                  <img src={img} alt="" />
                </div>
                <div className="col-12 col-md-6 mt-4 mt-md-0">
                  <h2 className="m-0">Ready to Take the Next Step?</h2>
                  <p>
                    Start your journey towards financial success with BT World
                    Education today. Subscribe to our program, refer your
                    friends, and become part of our growing community. Unlock
                    your financial potential and take control of your future.
                    Join us now!
                  </p>
                  {/* <WagmiProvider>
                    <WalletButton />
                  </WagmiProvider> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Cta;